let apiPromiseCache = {};

const isFunction = function(target) {
  return Object.prototype.toString.call(target) === '[object Function]';
};

function clearCache(cacheKey) {
  delete apiPromiseCache[cacheKey];
}

function ApiService(options) {
  const { apiConfig: config, http, Vue } = options;

  const apiService = {};
  const observerbel = Vue
    ? Vue.observebel || (target => new Vue({ data: target }))
    : null;

  Object.entries(config).forEach(([apiName, apiConfig]) => {
    const { url, method, config } = apiConfig;
    const api = {};
    apiService[apiName] = api;

    api.loading = false;
    api.config = apiConfig;

    api.request = function(requestConfig = {}) {
      const { path = {}, query = {}, body = {} } = requestConfig;

      const requestUrl = isFunction(url) ? url(path) : url;

      requestConfig = Object.assign({}, config, requestConfig, {
        url: requestUrl,
        method,
        params: query,
        data: body,
      });

      const cacheKey = `${requestConfig.url}__${JSON.stringify(
        query
      )}__${JSON.stringify(body)}`;
      const clearOwnCache = () => clearCache(cacheKey);
      const { cache, cacheTime, debounce } = { ...apiConfig, ...requestConfig };
      return new Promise((resolve, reject) => {
        // 防抖
        if (api.loading && debounce) return resolve({ data: {} });

        api.loading = true;

        const requestPromise =
          apiPromiseCache[cacheKey] || http.request(requestConfig);

        if (cache && !apiPromiseCache[cacheKey]) {
          apiPromiseCache[cacheKey] = requestPromise;

          if (cacheTime) {
            setTimeout(clearOwnCache, cacheTime);
          }
        }

        requestPromise
          .then(
            res => resolve(res),
            err => {
              clearOwnCache();
              reject(err);
            }
          )
          .finally(() => {
            api.loading = false;
          });
      });
    };

    api.clearCache = function() {
      const newCache = {};

      Object.keys(apiPromiseCache).forEach(cacheKey => {
        if (!cacheKey.includes(url)) {
          newCache[cacheKey] = apiPromiseCache[cacheKey];
        }
      });

      apiPromiseCache = newCache;
    };
  });

  return observerbel ? observerbel(apiService) : apiService;
}

ApiService.clearAllCache = function() {
  apiPromiseCache = {};
};

export default ApiService;
