import { getOptions } from '@/utils/data-map';


export const BASE_DATA_TYPE = {
  // 肥料类型
  FERTILIZER_TYPE: 1,
  // 施肥阶段
  FERTILIZATION_STAGE: 2,
  // 土质
  SOIL: 3,
  // 种植方式
  PLANTING_METHOD: 4,
  // 灌溉设备
  IRRIGATION_EQUIPMENT: 5,
  // 降雨量
  RAINFALL: 6,
  // 地形，描述土地的
  TERRAIN: 7,
  // 土性
  EARTHY: 8,
  // 土色
  LAND_COLOR: 9,
  // 土壤团粒结构
  SOIL_STRUCTURE: 10,
  // 排水性
  DRAINAGE: 11,
  // 深度
  DEPTH: 12,
  // 单位
  UNIT: 13,
  // 酸碱性
  ACID_BASE: 14,
  // 日照
  SUNSHINE: 15,
  // 形状，描述肥料的
  SHAPE: 16,
};

export const BASE_DATA_TYPE_LABEL = {
  [BASE_DATA_TYPE.FERTILIZER_TYPE]:localStorage.getItem('locale') === 'ja' ?"肥料種類": '肥料类型',
  [BASE_DATA_TYPE.FERTILIZATION_STAGE]: localStorage.getItem('locale') === 'ja' ?"施肥段階":'施肥阶段',
  [BASE_DATA_TYPE.SOIL]: localStorage.getItem('locale') === 'ja' ?"土の品質":'土质',
  [BASE_DATA_TYPE.PLANTING_METHOD]: localStorage.getItem('locale') === 'ja' ?"栽培方式":'种植方式',
  [BASE_DATA_TYPE.IRRIGATION_EQUIPMENT]: localStorage.getItem('locale') === 'ja' ?"潅水設備":'灌溉设备',
  [BASE_DATA_TYPE.RAINFALL]: localStorage.getItem('locale') === 'ja' ?"降雨量":'降雨量',
  [BASE_DATA_TYPE.TERRAIN]: localStorage.getItem('locale') === 'ja' ?"地形":'地形',
  [BASE_DATA_TYPE.EARTHY]: localStorage.getItem('locale') === 'ja' ?"土の性質":'土性',
  [BASE_DATA_TYPE.LAND_COLOR]: localStorage.getItem('locale') === 'ja' ?"土の色":'土色',
  [BASE_DATA_TYPE.SOIL_STRUCTURE]: localStorage.getItem('locale') === 'ja' ?"団粒構造":'土壤团粒结构',
  [BASE_DATA_TYPE.DRAINAGE]: localStorage.getItem('locale') === 'ja' ?"排水":'排水性',
  [BASE_DATA_TYPE.DEPTH]: localStorage.getItem('locale') === 'ja' ?"深さ":'深度',
  [BASE_DATA_TYPE.UNIT]: localStorage.getItem('locale') === 'ja' ?"単位":'单位',
  [BASE_DATA_TYPE.ACID_BASE]: localStorage.getItem('locale') === 'ja' ?"ＰＨ":'酸碱性',
  [BASE_DATA_TYPE.SUNSHINE]: localStorage.getItem('locale') === 'ja' ?"採光":'日照',
  [BASE_DATA_TYPE.SHAPE]: localStorage.getItem('locale') === 'ja' ?"形状":'形状',
};

export const BASE_DATA_TYPE_OPTIONS = getOptions(
  Object.keys(BASE_DATA_TYPE).map(key => BASE_DATA_TYPE[key]),
  BASE_DATA_TYPE_LABEL
);
