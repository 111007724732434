import { MessageBox } from 'element-ui';

const elAlert = MessageBox.alert;

const alert = function({
  message,
  title = '提示',
  confirmButtonText = localStorage.getItem('locale') === 'ja' ? '了解' : '知道了',
  dangerouslyUseHTMLString = true,
  ...opt
} = {}) {
  return new Promise(resolve => {
    elAlert(`<div class="text-align-center">${message}</div>`, title, {
      confirmButtonText,
      dangerouslyUseHTMLString,
      ...opt,
    })
      .then(resolve)
      .catch(resolve);
  });
};

alert.loginInvalid = function() {
  return alert({
    message: localStorage.getItem('locale') === 'ja' ? 'ログイン情報が無効になり、システムはログインページに戻ります' : '登录信息失效，系统将返回至登录页',
  });
};

alert.trailTip = function() {
  return alert({
    message:  localStorage.getItem('locale') === 'ja' ? '現在試用版ですので、ご購入の際はご連絡ください：' : '您当前为试用版,如需购买请联系:XX-XXXXXXXX',
  });
};

export const trailUserInvalidTip = localStorage.getItem('locale') === 'ja' ? '試用期間が足りませんので、再体験が必要な場合は管理者にご連絡ください' : '试用时长不足,如需再次体验请联系管理员';
alert.trialUserInvalid = function() {
  return alert({
    message: trailUserInvalidTip,
  });
};

export const generalUserInvalidTip =localStorage.getItem('locale') === 'ja' ? 'アカウントが満期になりましたので、引き続きご利用の場合は管理者にご連絡ください' :  '账号已到期,如需继续使用请联系管理员' ;
alert.generalUserInvalid = function() {
  return alert({
    message: generalUserInvalidTip,
  });
};

export default alert;
