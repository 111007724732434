import { ROLE } from '@/data-map/role';
import { isDev } from '@/utils/util';

/*
 *  数据结构
 *  {
 *     路由名: 操作权限标志[]
 *  }
 */
const permissionMap = {
  // 系统管理员
  [ROLE.SYSTEM_ADMINISTRATOR]: {
    // 用户管理
    'user-management': [],
    'user-management-list': [],
  },
  // 管理员（专家）
  [ROLE.ADMINISTRATOR]: {
    // 基础数据
    'base-data': [],
    'base-data-list': [],
    // 肥料管理
    'fertilizer-management': [],
    'fertilizer-management-list': [],
    'fertilizer-management-list-create': [],
    'fertilizer-management-list-edit': [],
    'fertilizer-management-list-detail': [],
    // 作物管理
    'crop-management': [],
    'crop-management-list': [],
    'crop-management-type-list': [],
    // 土地管理
    'land-management': [],
    'land-management-list': [],
    'land-management-list-create': [],
    'land-management-list-edit': [],
    'land-management-list-detail': [],
    // 施肥设计
    'land-management-fertilize-design': [],
    'land-management-fertilize-design-edit': [],
    'land-management-fertilize-design-detail': [],
    'land-management-fertilize-design-report': [],
  },
  // 普通用户（种植户）
  [ROLE.GENERAL_USER]: {
    // 土地管理
    'land-management': [],
    'land-management-list': [],
    'land-management-list-create': [],
    'land-management-list-edit': [],
    'land-management-list-detail': [],
    // 施肥设计
    'land-management-fertilize-design': [],
    'land-management-fertilize-design-edit': [],
    'land-management-fertilize-design-detail': [],
    'land-management-fertilize-design-report': [],
  },
  [ROLE.TEMP_USER]: {
    // 肥料管理
    'fertilizer-management': [],
    'fertilizer-management-list': [],
    'fertilizer-management-list-create': [],
    'fertilizer-management-list-edit': [],
    'fertilizer-management-list-detail': []
  }
};

// 目前试用用户的权限和种植户一样
permissionMap[ROLE.TRIAL_USER] = permissionMap[ROLE.GENERAL_USER];

export function filterMenuByRoleId(menus, roleId) {
  return menus.filter(menu => {
    const hasModulePermission = hasPermission(menu.name, roleId);
    const hasChildModulePermission =
      !menu.childPermitRouteNames ||
      !menu.childPermitRouteNames.length ||
      menu.childPermitRouteNames.some(name => hasPermission(name, roleId));

    return isDev() || (hasModulePermission && hasChildModulePermission);
  });
}

export function filterHeaderMenuByRoleId(menus, roleId) {
  return menus.filter(menu => {
    return isDev() || hasPermission(menu.name, roleId);
  });
}

// 是否是已失效的试用用户
export function isTrialUserAndInvalid(roleId, expire) {
  return roleId === ROLE.TRIAL_USER && expire === 0;
}

// 是否是已失效的种植户
export function isGeneralUserAndInvalid(roleId, expire) {
  return roleId === ROLE.GENERAL_USER && expire === 0;
}

export function hasPermission(routeName, roleId) {
  return (
    isDev() || roleId === ROLE.SUPERTUBE || routeName in permissionMap[roleId]
  );
}

export function getOprateCodeList(routeName, roleId) {
  return roleId === ROLE.SUPERTUBE ? [] : permissionMap[roleId][routeName];
}
