// 神策
import sensors from'sa-sdk-javascript';

sensors.init({
  server_url: 'https://sensorsdata.pagoda.com.cn:8106/sa?project=shop_production',
  is_track_single_page: true,
  show_log: false,
  use_client_time:true,
  heatmap: {
     //是否开启点击图，默认 default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭
     //需要 JSSDK 版本号大于 1.7
     clickmap:'default',
     //是否开启触达注意力图，默认 default 表示开启，自动采集 $WebStay 事件，可以设置 'not_collect' 表示关闭
     //需要 JSSDK 版本号大于 1.9.1
     scroll_notice_map:'default'
  }
});
sensors.quick('autoTrack');
sensors.use('PageLeave');
sensors.use('PageLoad');
sensors.registerPage({
  title: '优农道',
  product_line: 'ynd',
  env: process.env.VUE_APP_ENV
})
// 是否设置用户信息标记
let isSensorsLogin = false
// 登录后采集用户信息
export const logingSensors = (user) => {
  if (isSensorsLogin) return
  // 登记用户唯一标识，推荐使用oa账号id
  sensors.login(user.mobile)
  // 扩展更多用户信息字段，可按需设置
  sensors.setProfile({username: user.username, mobile: user.mobile})
  isSensorsLogin = true
}