import {
  isNull,
  isUndefined,
  isObject,
  isArray,
  isString,
  isFunction,
  isNumber,
} from 'lodash-es';
import dayjs from 'dayjs';
import { getPropByPath } from 'element-ui/lib/utils/util';
import NP from 'number-precision';

export function getPropertyValueFromRow(row, property) {
  const value = property && getPropByPath(row, property).v;

  return value;
}

export function isNumberOrNumberString(target) {
  return (
    (isString(target) || isNumber(target)) &&
    target !== '' &&
    isNumber(Number(target)) &&
    !isNaN(Number(target))
  );
}

export function formatterPercentage(obj, props, up) {
  props = props || Object.keys(obj);

  props.forEach(prop => {
    const val = obj[prop];

    if (isNumberOrNumberString(val)) {
      obj[prop] = up ? NP.times(val, 100) : NP.divide(val, 100);
    } else if (isArray(val)) {
      formatterPercentage(val, null, up);
    }
  });
}

export function importAll(mapOrCb, r, moduleName = 'default') {
  r.keys().forEach(key => {
    const source = moduleName ? r(key)[moduleName] : r(key);
    if (isArray(mapOrCb)) {
      
      mapOrCb.push(source);
    } else if (isFunction(mapOrCb)) {
      mapOrCb(source);
    } else {
      // 驼峰
      const fileName = key
        .split('/')
        .pop()
        .split('.')
        .shift()
        .replace(/-(\w)/g, function($0, $1) {
          return $1.toUpperCase();
        });
      mapOrCb[fileName] = source;
    }
  });
}

export function getAttrByKey(attrs, key) {
  // 转中划线
  const key2 = key.replace(/([A-Z])/g, '-$1').toLowerCase();

  return key in attrs ? attrs[key] : attrs[key2];
}

export function isAttrTrue(value) {
  return value === '' || value === true;
}

export function filterEmptyValue(obj) {
  let res = {};

  Object.keys(obj).forEach(key => {
    const value = obj[key];

    if (!isEmpty(value)) {
      res[key] = value;
    }
  });

  return res;
}

export const isEmpty = target => {
  if (
    isUndefined(target) ||
    isNull(target) ||
    (isNumber(target) && isNaN(target))
  ) {
    return true;
  } else if (isString(target)) {
    return target.trim().length === 0;
  } else if (isObject(target)) {
    return Object.keys(target).length === 0;
  } else if (isArray(target)) {
    return target.length === 0;
  }
  return false;
};

export function dateFormate(date) {
  return dayjs(new Date(date)).format('YYYY-MM-DD HH:mm:ss');
}

// 检测对象中的某些字段是否有空值
export function hasEmptyProp(obj, props) {
  return Object.keys(obj).some(key => {
    const value = obj[key];

    if (props.includes(key)) {
      return isEmpty(value);
    }
  });
}

// 判断某个对象里的所有属性是否为空
export function isEmptyRow(obj, ignoreProps) {
  return Object.keys(obj).every(key => {
    const value = obj[key];

    return ignoreProps.includes(key) ? true : isEmpty(value);
  });
}

export function isDev() {
  // return process.env.VUE_APP_ENV === 'development';
  return false;
}

// 获取该日期的 23:59:59
export function getDateLastTime(date) {
  return dayjs(new Date(date))
    .hour(23)
    .minute(59)
    .second(59);
}

export function deepLoop(data, cb, childrenKey = 'children', parent) {
  data.forEach(item => {
    cb(item, parent);

    if (childrenKey in item && item[childrenKey].length) {
      deepLoop(item[childrenKey], cb, childrenKey, item);
    }
  });
}
