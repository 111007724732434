const ja = {
  luv: {
    "1001": "操作失敗",
    "1002": "詮索する",
    "1003": "削除する",
    "1004": "保存する",
    "1005": "キャンセルする",
    "1006": "確認する",
    "1007": "次",
    "1008": "操作",
    "1009": "番号",
    "1010": "ログアウト",
    "1011": "ログイン",
    "1012": "お申込",
    "1013": "携帯番号入力",
    "1014": "携帯に送信した検証コード",
    "1015": "検証コード獲得する",
    "1016": "システムはあなたの変更を保留しません",
    "1017": "提示",
    "1018": "携帯電話番号のフォーマットが間違っていますので、再入力してください",
    "1019": "パスワード送信しました",
    "1020": "確認",
    "1021": "ログアウトを確認しますか",
    "1022": "削除確認。",
    "1023": "あなたは削除します。",
    "1024": "削除後は回復できません",
    "1025": "提交",
    "1026": "新規",
    "1027": "優農道システムへようこそ",
    "1028": "開始日付",
    "1029": "終了日付",
    "1030": "保存確認。",
    "1031": "至",
    "1032": "携帯番号",
    "1033": "ニックネーム",
    "1034": "请输入不超过10个字符的",
    "1035": "10文字以内の文字しか入力できません。",
    "1036": "データありません",
    "1037": "サービスが異常である.",
    "1038": "編集",
    "1039": "優農道",
    "1040": "BLOF現代精密",
    "1041": "有機農業施肥設計ソフトウェアプラットフォーム",
    "1042": "作成時間",
    "1043": "ユーザー名入力",
    "1044": "図形検証コード",
    "1045": "図形検証コード誤り",
    "1046": "中国語名前",
    "1047": "日本語名前",
    "1048": "図形検証コード入力",
    "2001": "畑管理",
    "2002": "土地リスト",
    "2003": "新規土地",
    "2004": "土地名",
    "2005": "土地名を入力",
    "2006": "栽培作物",
    "2007": "栽培作物入力",
    "2008": "施肥設計",
    "2009": "測定値",
    "2010": "詳細情報",
    "2011": "施肥設計記録",
    "2012": "栽培記録",
    "2013": "基礎データ",
    "2014": "地形",
    "2015": "土の性質",
    "2016": "土の色",
    "2017": "団粒構造",
    "2018": "採光",
    "2019": "温度",
    "2020": "降雨量",
    "2021": "潅水設備",
    "2022": "排水",
    "2023": "省",
    "2024": "市",
    "2025": "区",
    "2026": "详细地址",
    "2027": "经纬度",
    "2029": "地域",
    "2031": "土の品質",
    "2032": "面積（ム）",
    "2033": "標高",
    "2034": "栽培形式",
    "2035": "施肥設計日付",
    "2036": "調整",
    "2037": "施肥段階",
    "2038": "ＰＨ調整肥料",
    "2039": "肥料名（ＰＨ保持）",
    "2040": "肥料名（ＰＨ UP）",
    "2041": "肥料名（ＰＨ DOWN）",
    "2042": "単位重量",
    "2043": "年間窒素量設定",
    "2044": "測定値調整",
    "2045": "施肥設計報告書作成",
    "2046": "施肥設計報告書確認",
    "2047": "年間総窒素量設計",
    "2048": "年度窒素総量設定",
    "2049": "基肥割合",
    "2050": "有機肥料割合",
    "2051": "堆肥割合",
    "2052": "実肥割合",
    "2053": "礼肥割合",
    "2054": "施肥設計報告書",
    "2055": "出力する",
    "2056": "地形を選択",
    "2057": "土の性質選択",
    "2058": "土の色選択",
    "2059": "団粒構造選択",
    "2060": "日当たり選択",
    "2061": "降雨量選択",
    "2062": "灌漑施設選択",
    "2063": "排水性選択",
    "2064": "土壌の詳しい住所を記入してください。",
    "2065": "経度を入力してください",
    "2066": "緯度を入力してください",
    "2067": "土の質",
    "2068": "面積入力",
    "2069": "標高入力",
    "2070": "栽培方式選択",
    "2071": "肥料名前入力",
    "2072": "作物分類名を入力してください",
    "2073": "作物類名を入力してください",
    "2074": "キーワードを入力してフィルタリングしてください",
    "2075": "温度は-99-99以内の数字しか入力できません",
    "2076": "省を選んでください。",
    "2078": "市を選んでください。",
    "2079": "エリアを選んでください",
    "2080": "ム",
    "2081": "メートル",
    "2082": "前のページ",
    "2083": "土地名は15以内の文字のみ入力できます",
    "2084": "標高は0~10000以内の数字しか入力できません",
    "2085": "面積は0-10000以内の数字しか入力できません",
    "2086": "面積（ム）",
    "2087": "標高（Ｍ）",
    "2088": "まず完全な実測値(亜鉛\/シリコン\/銅\/塩分\/ホウ素\/腐植\/硫黄\/亜鉛\/シリコン\/銅を除く)を記入してください",
    "2089": "写真の大きさは超えてはいけません。",
    "2090": "アップロードできる",
    "2091": "枚写真.",
    "2092": "タイプは構成されていません",
    "2093": "礼肥：10cm",
    "2094": "実肥：10cm",
    "2095": "基肥（3年以上）：30cm",
    "2096": "基肥（3年以下）：20cm",
    "2097": "上がる",
    "2098": "変わらない",
    "2099": "下がる",
    "2100": "中性肥料",
    "2101": "窒素設定",
    "2102": "窒素",
    "2103": "礼肥",
    "2104": "実肥",
    "2105": "基肥",
    "2106": "有機肥料",
    "2107": "堆肥",
    "2108": "生成施肥報告成功",
    "2109": "施肥設計記録の保存に成功した",
    "2110": "この作物には上下制限が設けられていませんので、管理者に連絡して作物情報を完備し",
    "2111": "当面の土地は作物を栽培していないので，しばらく施肥設計を行うことができない",
    "2112": "まず全年度の総窒素量設計を記入してください",
    "2113": "肥料比率はマイナスにすることはできませんので、肥料比率を再調整してください",
    "2114": "礼肥比率をお選びください。",
    "2115": "基肥比率をお選びください。",
    "2116": "有機肥料を選んでください。",
    "2117": "保存年度の総窒素量設計に成功した",
    "2118": "まず完全な実測値(ホウ素\/腐植\/硫黄\/亜鉛\/シリコン\/銅を除く)を記入してください",
    "2119": "実測値を保存することに成功した",
    "2120": "報告日付",
    "2121": "施肥量",
    "2122": "数量",
    "2123": "単位量",
    "2124": "元素名",
    "2125": "補充量",
    "2126": "施肥設計報告",
    "2127": "成功を導く",
    "2128": "削除成功",
    "2129": "前値<後値の温度範囲を入力してください",
    "2130": "詳細アドレス長は40文字を超えておりますので、再入力してください",
    "2131": "栽培日",
    "2132": "栽培周期",
    "2133": "使用量",
    "2134": "自分",
    "2135": "全部",
    "2136": "秋処理割合",
    "2137": "基肥割合",
    "2138": "ユーザー",
    "2139": "アミノ酸肥",
    "2140": "本人だけ",
    "2141": "名前入力",
    "2142": "畑の中国語",
    "2143": "畑の日本語",
    "3001": "作物管理",
    "3002": "作物リスト",
    "3003": "作物タイプ",
    "3004": "作物名前",
    "3005": "作物名前入力",
    "3006": "作物タイプ",
    "3007": "選択",
    "3008": "新規作物",
    "3009": "上限値と下限値調整",
    "3010": "深さ",
    "3011": "作物分類",
    "3012": "作物分類",
    "3013": "深さ／ｃｍ",
    "3014": "プロジェクト名",
    "3015": "下限値",
    "3016": "上限値",
    "3017": "CEC計算",
    "3018": "PH (水)",
    "3019": "PH(KCL)",
    "3020": "NH4",
    "3021": "NO3",
    "3022": "腐植",
    "3023": "塩分",
    "3024": "新規作物分類",
    "3025": "新規分類",
    "3026": "有効状態",
    "3027": "全作物分類",
    "3028": "作物分類名前",
    "3029": "作物タイプをお選びください",
    "3030": "10文字以下の作物名を入力してください",
    "3031": "作物名は10文字以内の文字のみ入力できます",
    "3032": "作物分類を選んでください。",
    "3033": "作物の根の深さをお選びください",
    "3034": "下限値は上限値を超えてはいけない。",
    "3035": "上限値は下限値より小さくてはいけない",
    "3036": "キーワード検索を入力してください。",
    "3037": "下位設定に保存して適用する。",
    "3038": "あなたは今回の操作を保存して下位設定に適用します。",
    "3039": "カリウム、カルシウム、マグネシウムの上下限界値は0ではありません。",
    "3040": "保存に成功する。",
    "3041": "状態を選択してください。",
    "3042": "土地を選んでください。",
    "3043": "作物",
    "3044": "作物編集",
    "3045": "順位付けが成功した。",
    "3046": "新規成功。",
    "3047": "修正が成功する。",
    "3048": "新規作物品目",
    "3049": "作物分類名。",
    "3050": "編集作物分類",
    "3051": "作物類を編集する",
    "3052": "10文字以下の作物の分類名を入力してください。",
    "3053": "10文字以下の作物の種類名を入力してください。",
    "3054": "作物分類中国語名前",
    "3055": "作物品目中国語名前",
    "3056": "作物分類日本語名前",
    "3057": "作物分類日本語名前",
    "4001": "肥料管理",
    "4002": "肥料リスト",
    "4003": "新規肥料",
    "4004": "肥料名",
    "4005": "肥料名前入力",
    "4006": "肥料種類",
    "4007": "肥料タイプをお選びください",
    "4008": "窒素定数",
    "4009": "窒素",
    "4010": "リン酸",
    "4011": "加里",
    "4012": "石灰",
    "4013": "苦土",
    "4014": "ホウ素",
    "4015": "マンガン",
    "4016": "鉄",
    "4017": "硫黄",
    "4018": "亜鉛",
    "4019": "珪素",
    "4020": "銅",
    "4021": "単位",
    "4022": "仕様\/kg",
    "4023": "単位\/元",
    "4024": "ＰＨ",
    "4025": "pH",
    "4026": "C\/N比",
    "4027": "施肥段階",
    "4028": "作物分類",
    "4029": "形状",
    "4030": "特性",
    "4031": "原料",
    "4032": "使用方法",
    "4033": "写真",
    "4034": "施肥量最大限",
    "4035": "仕様を入力してください",
    "4036": "単価を入力してください",
    "4037": "最大施肥量を入力してください",
    "4038": "请选择PH值情况",
    "4040": "C\/N比は0~1000以内の数字しか入力できません。",
    "4041": "施肥段階をお選びください。",
    "4042": "適用作物分類を選択してください",
    "4043": "ヒント：肥料割合の総和は≦100%である必要がある",
    "4044": "元素総占有比",
    "4045": "入力してください",
    "4046": "特性を入力してください",
    "4047": "原材料を入力してください",
    "4048": "使い方を入力してください",
    "4049": "窒素吸収率を入力してください",
    "4050": "C\/N比を入力してください",
    "4051": "仕様",
    "4052": "単価",
    "4053": "元",
    "4054": "肥料名は20以内の文字のみ入力できます",
    "4055": "Ph変化値は0(含まない)-100以内の数字のみを入力することができる.。",
    "4056": "窒素吸収率は0(含まない)−1以内の数字のみを入力することができる。",
    "4057": "単位を選んでください",
    "4058": "サイズは0-1000以内の数字のみ入力できます。",
    "4059": "単価は0~10000以内の数字のみ入力できます",
    "4060": "最大施肥量は1−5000以内の数字しか入力できない。",
    "4061": "施肥適用段階をお選びください",
    "4062": "0<肥料元素占有比合計≦100%ですので、再入力してください",
    "4063": "肥料の中国語",
    "4064": "肥料の日本語",
    "5001": "ユーザー管理",
    "5002": "ユーザー携帯番号",
    "5003": "携帯番号入力",
    "5004": "操作記録",
    "5005": "期間調整",
    "5006": "ユーザー属性",
    "5007": "土地の数",
    "5008": "使用期間調整",
    "5009": "ユーザ期間残り：",
    "5010": "使用期間",
    "5011": "ユーザー携帯番号",
    "5014": "登録日",
    "5015": "満了日",
    "5016": "残り",
    "5017": "日",
    "5018": "試用バージョン",
    "5019": "この携帯電話番号は未登録ですので、先に登録してください",
    "5020": "この携帯電話番号は登録しましたので、携帯電話番号が正しいかどうかチェックしてください",
    "5021": "操作機能選択",
    "5022": "スーパー管理人。",
    "5023": "システム管理員",
    "5024": "管理員",
    "5025": "農家",
    "5026": "ユーザーを試用する。",
    "5027": "了解",
    "5028": "ログイン情報が無効になり、システムはログインページに戻ります",
    "5029": "現在試用版ですので、ご購入の際はご連絡ください：",
    "5030": "試用期間が足りませんので、再体験が必要な場合は管理者にご連絡ください。",
    "5031": "アカウントが満期になりましたので、引き続きご利用の場合は管理者にご連絡ください",
    "5032": "土地の数が上限に達していますので、追加する必要があれば、管理者に連絡してください",
    "5033": "ユーザー編集",
    "5034": "ご利用期限をお選びください",
    "5035": "所属キャラクターをお選びください。",
    "5036": "土地の数を入力してください。",
    "5037": "最大1000入力しかできません",
    "5038": "調整成功しました",
    "5039": "操作日付",
    "5040": "操作機能",
    "5041": "操作対象",
    "5042": "内容",
    "5043": "内容を入力してください。",
    "5044": "操作者",
    "5045": "新規取引先",
    "5046": "グループを解散する",
    "5047": "メンバ追加",
    "5048": "グループを削除",
    "5049": "正しい携帯番号を記入してください。",
    "5050": "お客様の名前を入力してください",
    "5051": "お客様名はすでに存在しております",
    "5052": "技術者を追加するしかありません",
    "5053": "操作対象選択",
    "5054": "顧客名",
    "5055": "グループを解散すると、メンバー間でお互いの土地情報を見ることができなくなる。",
    "5056": "このメンバをグループから移すことを決定",
    "5057": "顧客グループ",
    "5058": "解散",
    "5059": "メンバーを選んでください",
    "5060": "ユーザー名",
    "6001": "基礎データ",
    "6002": "新規基礎データ",
    "6003": "名前入力",
    "6004": "基礎データ類別選択",
    "6006": "属性",
    "6008": "名前",
    "6009": "状態",
    "6010": "備考",
    "6011": "基礎データ類別",
    "6012": "有効",
    "6013": "無効",
    "6014": "基礎データ名を入力してください",
    "6015": "1~15文字の基礎データ名を入力してください",
    "6016": "200文字以内の備考を入力してください",
    "6017": "基礎データ編集",
    "6018": "基礎データ選択",
    "2144": "実際の窒素量"
  },
};
export default ja;