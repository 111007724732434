import axios from 'axios';
import { Message } from 'element-ui';
import Vue from 'vue';
import Router from '@/router/index';
// utils
import axiosApiService from '@/utils/api-service';
import { importAll, filterEmptyValue } from '@/utils/util';
import { exportFile, getJsonFromBlob } from '@/utils/export';
import alert from '@/utils/alert';
import { getTokenFromStorage, clearTokenInStorage } from '@/utils/token';

const apiConfig = {};

importAll(config => {
  Object.assign(apiConfig, config);
}, require.context('./config/', false, /.js/));

export const baseURL = window.location.protocol + process.env.VUE_APP_SERVICE;
axios.defaults.withCredentials = true
export const http = axios.create({
  baseURL
});

let loginInvalidAlertShow = false;

async function onError({ code, msg }, config) {
  // 登录失效 或者 试用用户试用期到了 或者 正式用户到期了
  if (code === 1004 || code === 2005 || code === 2006) {
    if (!loginInvalidAlertShow) {
      loginInvalidAlertShow = true;
      switch (code) {
        // 试用用户到期
        case 2005:
          await alert.trialUserInvalid();
          break;
        // 正式用户到期
        case 2006:
          await alert.generalUserInvalid();
          break;
        // 登录失效
        case 1004:
          await alert.loginInvalid();
          break;
      }
      loginInvalidAlertShow = false;

      clearTokenInStorage();
      Router.replace({
        name: 'login',
      });
    }
  } else {
    if (config.errorMessageType === 'alert') {
      alert({
        message: msg,
      });
    } else {
      Message.info(msg);
    }
  }
}

http.interceptors.request.use(config => {
  if (
    !config.headers['Content-Type'] ||
    config.headers['Content-Type'].includes('application/json')
  ) {
    config.data = filterEmptyValue(config.data);

    if (!config.notFilterEmptyValue) {
      if (config.data.params) {
        config.data.params = filterEmptyValue(config.data.params);
      }

      if (config.params) {
        config.params = filterEmptyValue(config.params);
      }
    }
  }

  if (config.exportConfig) {
    config.responseType = 'blob';
  }

  config.headers.token = getTokenFromStorage();
  config.headers["Accept-Language"] = localStorage.getItem('locale')==="ja"?"ja-jp":"zh-cn";
  return config;
});

http.interceptors.response.use(
  async response => {
    const { data: result, config } = response;
    // ignoreErrorCode 是否忽略错误码检测 pretreatment 是否数据预处理
    const {
      ignoreErrorCode = false,
      pretreatment = true,
      exportConfig = false,
    } = config;

    if (exportConfig) {
      const json = await getJsonFromBlob(result);
      // 如果返回对象则说明导出失败
      if (json) {
        onError(json, config);
        return Promise.reject('');
      } else {
        exportFile(result, exportConfig.fileName);
      }
    } else if (result.code === 0 || ignoreErrorCode) {
      return pretreatment ? result.data : result;
    } else {
      onError(result, config);
      return Promise.reject('');
    }
  },
  error => {
    console.error(error);

    Message.info(localStorage.getItem('locale') === 'ja' ?"サービスが異常である.": '服务异常');

    return Promise.reject(error);
  }
);

const API = axiosApiService({
  http,
  Vue,
  apiConfig,
});

export default API;
