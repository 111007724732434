const cacheKey = 'token';

let cacheToken = null;

export function getTokenFromStorage() {
  if (cacheToken) return cacheToken;

  return cacheToken || localStorage.getItem(cacheKey);
}

export function setTokenToStorage(token) {
  localStorage.setItem(cacheKey, token);
  cacheToken = token;
}

export function clearTokenInStorage() {
  localStorage.removeItem(cacheKey);
  cacheToken = null;
}
