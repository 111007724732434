import PagodaRemark from 'pagoda-ui/src/components/form/remark-cell';

export default {
  label:localStorage.getItem('locale')==='ja'?"備考": '备注',
  prop: 'note',
  minWidth: 140,
  renderColumn(h, scope) {
    const remark = scope.row[scope.column.property];

    return <PagodaRemark value={remark} editable={false}></PagodaRemark>;
  },
};
