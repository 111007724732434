export default {
  // 用户列表
  getUserList: {
    url: '/userManager/userManager/list',
    method: 'get',
  },
  // 编辑用户信息
  editUserInfo: {
    url: '/userManager/userManager/updateUserInfo',
    method: 'post',
    config: {
      notFilterEmptyValue: true,
    },
  },
  // 删除用户
  delUser: {
    url: '/userManager/userManager/deleteUser',
    method: 'post',
    config: {
      errorMessageType: 'alert',
    },
  },
  // 延长试用期
  incrTryTime: {
    url: '/userManager/userManager/incrTryTime',
    method: 'post',
  },
  // 操作日志
  getOperateLogList: {
    url: '/userManager/operationRecords/list',
    method: 'get',
  },
  // 获取操作名称列表
  getOprateNameList: {
    url: '/userManager/operationRecords/operationNameList',
    method: 'get',
  },
  // 获取操作对象列表
  getOprateObjectList: {
    url: '/userManager/operationRecords/operationObjectList',
    method: 'get',
  },
  // 获取分组列表
  getGroupList: {
    url: '/group/list',
    method: 'get',
  },
  // 添加分组客户
  addGroupUser:{
    url: '/group/add',
    method: 'post',
  },
  // 解散分组
  delGroup:{
    url: '/group/dismiss',
    method: 'post',
  },
  // 分组用户列表
  getGroupUserList:{
    url:'/group/member/list',
    method:'get'
  },
  // 添加分组用户
  addUser:{
    url:'/group/member/add',
    method:'post'
  },
  // 移除分组用户
  delGroupUser:{
    url:'/group/member/remove',
    method:'post'
  }
};
