import { getOptions } from '@/utils/data-map';

export const ROLE = {
  // 超管
  SUPERTUBE: 39,
  // 系统管理员
  SYSTEM_ADMINISTRATOR: 40,
  // 管理员（专家）
  ADMINISTRATOR: 41,
  // 普通用户（种植户）
  GENERAL_USER: 42,
  // 试用用户
  TRIAL_USER: 43,
  // temp user
  TEMP_USER: 44
};

export const ROLE_LABEL = {
  [ROLE.SUPERTUBE]:  localStorage.getItem('locale') === 'ja' ? 'スーパー管理人' : '超级管理员',
  [ROLE.SYSTEM_ADMINISTRATOR]:  localStorage.getItem('locale') === 'ja' ? 'システム管理員' : '系统管理员',
  [ROLE.ADMINISTRATOR]: localStorage.getItem('locale') === 'ja' ? '管理員' : '管理员',
  [ROLE.GENERAL_USER]:  localStorage.getItem('locale') === 'ja' ? '農家' : '种植户',
  [ROLE.TRIAL_USER]:  localStorage.getItem('locale') === 'ja' ? 'ユーザーを試用する' : '试用用户',
  [ROLE.TEMP_USER]:  localStorage.getItem('locale') === 'ja' ? '管理員' : '管理员'
};

export const ROLE_OPTIONS = getOptions(
  Object.keys(ROLE).map(key => ROLE[key]),
  ROLE_LABEL
);
