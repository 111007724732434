import dayjs from 'dayjs';
import { getAttrByKey, isAttrTrue } from '@/utils/util';

// 日期范围列 主要用来统一设置宽度，如果需要格式化，则开启shouldFormatter属性即可
export default {
  minWidth: 200,
  renderColumn(h, scope, ctx) {
    const {
      data: { attrs = {} },
    } = ctx;
    const shouldFormatter = isAttrTrue(getAttrByKey(attrs, 'shouldFormatter'));
    const startTime = getAttrByKey(attrs, 'startProp');
    const endTime = getAttrByKey(attrs, 'endProp');

    return `${
      shouldFormatter ? dayjs(startTime).format('YYYY-MM-dd') : startTime
    }-${shouldFormatter ? dayjs(endTime).format('YYYY-MM-dd') : endTime}`;
  },
};
