<template>
  <router-view />
</template>
<script>
export default {
  async created () {
    const zhData = await this.$API.getJsonData.request({ query: { 'lang': 'zh-cn' } })
    const jaData = await this.$API.getJsonData.request({ query: { 'lang': 'ja-jp' } })
    this.$i18n.mergeLocaleMessage('zh', { luv: zhData })
    this.$i18n.mergeLocaleMessage('ja', { luv: jaData })
  }
}
</script>
