export function getOptions(
  values,
  labelMap,
  labelKey = 'label',
  valueKey = 'value'
) {
  return values.map(val => ({
    [labelKey]: labelMap[val],
    [valueKey]: val,
  }));
}

export function getLabel(labelMap, value, emptyValue = '-') {
  return labelMap[value] || emptyValue;
}
