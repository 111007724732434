export default {
  // 作物列表
  getCropList: {
    url: '/crop/list',
    method: 'get',
  },
  // 新增作物
  addCrop: {
    url: '/crop/add',
    method: 'post',
  },
  // 更新作物
  updateCrop: {
    url: '/crop/update',
    method: 'post',
    config: {
      notFilterEmptyValue: true,
    },
  },
  // 删除作物
  delCrop: {
    url: '/crop/delete',
    method: 'post',
  },
  // 新增作物分类
  addCropClassification: {
    url: '/crop/classification/add',
    method: 'post',
  },
  // 更新作物分类
  updateCropClassification: {
    url: '/crop/classification/update',
    method: 'post',
    config: {
      notFilterEmptyValue: true,
    },
  },
  // 查询作物分类
  getCropClassification: {
    url: '/crop/classification/list',
    method: 'get',
  },
  // 获取分类上下限
  getClassificationLimit: {
    url: '/crop/classification/getLimit',
    method: 'get',
  },
  // 更新分类上下限
  updateClassificationLimit: {
    url: '/crop/classification/updateLimit',
    method: 'post',
    config: {
      notFilterEmptyValue: true,
    },
  },
  // 获取作物上下限
  getCropLimit: {
    url: '/crop/getLimit',
    method: 'get',
  },
  // 更新作物上下限
  updateCropLimit: {
    url: '/crop/updateLimit',
    method: 'post',
    config: {
      notFilterEmptyValue: true,
    },
  },
  // 作物排序
  sortCrop: {
    url: '/crop/swap',
    method: 'post',
  },
  // 作物分类排序
  sortClassification: {
    url: '/crop/classification/swap',
    method: 'post',
  },
};
