import { getOptions } from '@/utils/data-map';

export const ENABLED = {
  TRUE: 1,
  FALSE: 0,
};

export const ENABLED_LABEL = {
  [ENABLED.TRUE]: localStorage.getItem('locale') === 'ja' ?"有効":'有效',
  [ENABLED.FALSE]: localStorage.getItem('locale') === 'ja' ?"無効":'无效',
};

export const ENABLED_OPTIONS = getOptions(
  Object.keys(ENABLED).map(key => ENABLED[key]),
  ENABLED_LABEL
);
