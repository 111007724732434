export default {
  // 注册
  register: {
    url: '/user/register',
    method: 'post',
  },
  // 判断手机有没有注册
  isRegistered: {
    url: '/user/registered',
    method: 'post',
  },
  // 发送验证码
  verificationCode: {
    url: '/user/sendVerificationCode',
    method: 'post',
  },
  // 登录
  login: {
    url: '/user/login',
    method: 'post',
  },
  // 登出
  logout: {
    url: '/user/logout',
    method: 'post',
  },
  // 获取用户信息
  getUserInfo: {
    url: '/user/info',
    method: 'get',
  },
  // 省市区查询
  getCityInfo: {
    url: '/city/info',
    method: 'get',
  },
  // 上传图片
  uploadImage: {
    url: '/image/upload',
    method: 'post',
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  },
  // 获取属性数据
  getAttribute: {
    url: '/basicData/dataType',
    method: 'get',
  },
  getJsonData:{
    url:'/user/lang',
    method:'get'
  }
};
