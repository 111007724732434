import { isNumberOrNumberString } from '@/utils/util';
import NP from 'number-precision';

export default {
  minWidth: 60,
  formatter(row, column, cellValue) {
    return isNumberOrNumberString(cellValue)
      ? `${NP.times(cellValue, 100)}%`
      : cellValue;
  },
};
