export default {
  // 肥料列表
  getFertilizerList: {
    url: '/fertilizer/list',
    method: 'get',
  },
  // 新增肥料
  createFertilizer: {
    url: '/fertilizer/add',
    method: 'post',
  },
  // 肥料详情
  getFertilizerDetails: {
    url: '/fertilizer/details',
    method: 'get',
  },
  // 更新肥料信息
  updateFertilizer: {
    url: '/fertilizer/update',
    method: 'post',
    config: {
      notFilterEmptyValue: true,
    },
  },
  // 删除肥料
  delFertilizer: {
    url: '/fertilizer/delete',
    method: 'post',
  },
};
