const zh = {
  luv: {
    "1001": "操作失败",
    "1002": "查询",
    "1003": "删除",
    "1004": "保存",
    "1005": "取消",
    "1006": "查看",
    "1007": "下一步",
    "1008": "操作",
    "1009": "序号",
    "1010": "退出登录",
    "1011": "登录",
    "1012": "注册",
    "1013": "请输入手机号",
    "1014": "请输入短信验证码",
    "1015": "获取验证码",
    "1016": "系统将不保留你所做的更改",
    "1017": "提示",
    "1018": "手机号格式错误，请重新输入",
    "1019": "验证码发送成功",
    "1020": "确定",
    "1021": "是否确认退出登录",
    "1022": "删除确认",
    "1023": "你将删除",
    "1024": "删除后将不可恢复",
    "1025": "提交",
    "1026": "新增",
    "1027": "欢迎来到优农道系统",
    "1028": "开始日期",
    "1029": "结束日期",
    "1030": "保存确认",
    "1031": "至",
    "1032": "手机号",
    "1033": "角色名称",
    "1034": "请输入不超过10个字符的",
    "1035": "仅可输入10个以内的字符",
    "1036": "暂无数据",
    "1037": "服务异常",
    "1038": "编辑",
    "1039": "优农道",
    "1040": "BLOF现代精准",
    "1041": "有机农业施肥设计软件平台",
    "1042": "创建时间",
    "1043": "请输入用户名",
    "1044": "图形验证码",
    "1045": "图形验证码错误",
    "1046": "中文名称",
    "1047": "日文名称",
    "1048": "请输入图形验证码",
    "2001": "土地管理",
    "2002": "土地列表",
    "2003": "新增土地",
    "2004": "土地名称",
    "2005": "请输入土地名称",
    "2006": "种植作物",
    "2007": "请选择种植作物",
    "2008": "施肥设计",
    "2009": "实测值",
    "2010": "详细信息",
    "2011": "施肥设计记录",
    "2012": "种植记录",
    "2013": "基础信息",
    "2014": "地形",
    "2015": "土性",
    "2016": "土色",
    "2017": "土壤团粒结构",
    "2018": "日照",
    "2019": "温度",
    "2020": "降雨量",
    "2021": "灌溉设备",
    "2022": "排水性",
    "2023": "省",
    "2024": "市",
    "2025": "区",
    "2026": "详细地址",
    "2027": "经纬度",
    "2029": "省市区",
    "2031": "土质",
    "2032": "面积",
    "2033": "海拔",
    "2034": "种植方式",
    "2035": "施肥设计日期",
    "2036": "调整",
    "2037": "施肥阶段",
    "2038": "PH调整肥料",
    "2039": "肥料名称（PH不变）",
    "2040": "肥料名称（PH升高）",
    "2041": "肥料名称（PH降低）",
    "2042": "单位重量",
    "2043": "年度氮量设定",
    "2044": "调整实测值",
    "2045": "生成施肥分析报告",
    "2046": "查看施肥分析报告",
    "2047": "年度总用氮设计",
    "2048": "年度总氮量设定",
    "2049": "基肥占比",
    "2050": "有机肥占比",
    "2051": "堆肥占比",
    "2052": "实肥占比",
    "2053": "礼肥占比",
    "2054": "施肥分析报告",
    "2055": "导出",
    "2056": "请选择地形",
    "2057": "请选择土性",
    "2058": "请选择土色",
    "2059": "请选择土壤团粒结构",
    "2060": "请选择日照",
    "2061": "请选择降雨量",
    "2062": "请选择灌溉设备",
    "2063": "请选择排水性",
    "2064": "请输入详细地址",
    "2065": "请输入经度",
    "2066": "请输入维度",
    "2067": "请选择土质",
    "2068": "请输入面积",
    "2069": "请输入海拔",
    "2070": "请选择种植方式",
    "2071": "请输入肥料名称",
    "2072": "请输入作物分类名称",
    "2073": "请输入作物品类名称",
    "2074": "请输入关键字进行过滤",
    "2075": "温度仅可输入-99-99以内的数字",
    "2076": "请选择省",
    "2078": "请选择市",
    "2079": "请选择区",
    "2080": "亩",
    "2081": "米",
    "2082": "返回上级",
    "2083": "土地名称仅可输入15以内的字符",
    "2084": "海拔仅可输入0-10000以内的数字",
    "2085": "面积仅可输入0-10000以内的数字",
    "2086": "面积(亩)",
    "2087": "海拔(米)",
    "2088": "请先填写完整的实测值(锌\/硅\/铜\/盐分\/硼\/腐殖\/硫\/锌\/硅\/铜除外)",
    "2089": "图片大小不能超过",
    "2090": "最多能上传",
    "2091": "张图片",
    "2092": "类型没有配置",
    "2093": "礼肥：10cm",
    "2094": "实肥：10cm",
    "2095": "基肥（3年以上）：30cm",
    "2096": "基肥（3年以下）：20cm",
    "2097": "升高",
    "2098": "不变",
    "2099": "降低",
    "2100": "中性肥料",
    "2101": "氮量设定",
    "2102": "当前氮量",
    "2103": "礼肥",
    "2104": "实肥",
    "2105": "基肥",
    "2106": "有机肥",
    "2107": "堆肥",
    "2108": "生成施肥报告成功",
    "2109": "保存施肥设计记录成功",
    "2110": "此作物未设置上下限，请联系管理员完善作物信息",
    "2111": "当前土地没有种植作物，暂时无法进行施肥设计",
    "2112": "请先填写完整年度总氮量设计",
    "2113": "肥料占比不能为负数，请重新调整肥料占比",
    "2114": "请选择礼肥占比",
    "2115": "请选择基肥占比",
    "2116": "请选择有机肥占比",
    "2117": "保存年度总氮量设计成功",
    "2118": "请先填写完整的实测值(硼\/腐殖\/硫\/锌\/硅\/铜除外)",
    "2119": "保存实测值成功",
    "2120": "报告时间",
    "2121": "施肥用量",
    "2122": "数量",
    "2123": "单位用量",
    "2124": "元素名称",
    "2125": "补充量",
    "2126": "施肥设计报告",
    "2127": "导出成功",
    "2128": "删除成功",
    "2129": "请输入前值＜后值的温度范围",
    "2130": "详细地址长度超过40个字符,请重新输入",
    "2131": "种植日期",
    "2132": "种植周期",
    "2133": "用量",
    "2134": "自己",
    "2135": "全部",
    "2136": "秋季处理占比",
    "2137": "底肥占比",
    "2138": "用户",
    "2139": "氨基酸肥",
    "2140": "仅本人",
    "2141": "请输入姓名",
    "2142": "土地中文名称",
    "2143": "土地日文名称",
    "3001": "作物管理",
    "3002": "作物列表",
    "3003": "作物类型",
    "3004": "作物名称",
    "3005": "请输入作物名称",
    "3006": "作物类型",
    "3007": "请选择",
    "3008": "新增作物",
    "3009": "调整上下限",
    "3010": "根系深度",
    "3011": "作物分类",
    "3012": "作物品类",
    "3013": "根系深度\/cm",
    "3014": "项目名称",
    "3015": "下限值",
    "3016": "上限值",
    "3017": "使用CEC限制",
    "3018": "PH (水)",
    "3019": "PH (氯化钾)",
    "3020": "铵态氮",
    "3021": "硝态氮",
    "3022": "腐植",
    "3023": "盐分",
    "3024": "新增作物分类",
    "3025": "新增品类",
    "3026": "有效状态",
    "3027": "全部作物分类",
    "3028": "作物品类名称",
    "3029": "请选择作物类型",
    "3030": "请输入不超过10个字符的作物名称",
    "3031": "作物名称仅可输入10个以内的字符",
    "3032": "请选择作物分类",
    "3033": "请选择作物根系深度",
    "3034": "下限值不能大于上限值",
    "3035": "上限值不能小于下限值",
    "3036": "请输入关键字搜索",
    "3037": "保存并应用到下级设置",
    "3038": "你将保存本次操作并运用到下级设置",
    "3039": "钾、钙、镁上下限值不能为0",
    "3040": "保存成功",
    "3041": "请选择状态",
    "3042": "请选择土地",
    "3043": "作物",
    "3044": "编辑作物",
    "3045": "排序成功",
    "3046": "新增成功",
    "3047": "修改成功",
    "3048": "新增作物品类",
    "3049": "作物分类名称",
    "3050": "编辑作物分类",
    "3051": "编辑作物品类",
    "3052": "请输入不超过10个字符的作物分类名称",
    "3053": "请输入不超过10个字符的作物品类名称",
    "3054": "作物分类中文名称",
    "3055": "作物品类中文名称",
    "3056": "作物分类日文名称",
    "3057": "作物品类日文名称",
    "4001": "肥料管理",
    "4002": "肥料列表",
    "4003": "新增肥料",
    "4004": "肥料名称",
    "4005": "请输入肥料名称",
    "4006": "肥料类型",
    "4007": "请选择肥料类型",
    "4008": "氮吸收率",
    "4009": "氮",
    "4010": "磷",
    "4011": "钾",
    "4012": "钙",
    "4013": "镁",
    "4014": "硼",
    "4015": "锰",
    "4016": "铁",
    "4017": "硫",
    "4018": "锌",
    "4019": "硅",
    "4020": "铜",
    "4021": "单位",
    "4022": "规格\/kg",
    "4023": "单位\/元",
    "4024": "酸碱性",
    "4025": "PH值情况",
    "4026": "碳氮比",
    "4027": "适用施肥阶段",
    "4028": "适用作物分类",
    "4029": "形状",
    "4030": "特性",
    "4031": "原料",
    "4032": "使用方法",
    "4033": "图片",
    "4034": "最大施肥用量",
    "4035": "请输入规格",
    "4036": "请输入单价",
    "4037": "请输入最大施肥用量",
    "4038": "请选择PH值情况",
    "4040": "碳氮比仅可输入0-1000以内的数字",
    "4041": "请选择使用施肥阶段",
    "4042": "请选择适用作物分类",
    "4043": "提示：肥料元素占比总和需≤100%",
    "4044": "元素总占比",
    "4045": "请输入",
    "4046": "请输入特性",
    "4047": "请输入原料",
    "4048": "请输入使用方法",
    "4049": "请输入氮吸收率",
    "4050": "请输入碳氮比",
    "4051": "规格",
    "4052": "单价",
    "4053": "元",
    "4054": "肥料名称仅可输入20以内的字符",
    "4055": "ph变化值仅可输入0（不含）-100以内的数字",
    "4056": "氮吸收率仅可输入0（不含）-1以内的数字",
    "4057": "请选择单位",
    "4058": "规格仅可输入0-1000以内的数字",
    "4059": "单价仅可输入0-10000以内的数字",
    "4060": "最大施肥用量仅可输入1-5000以内的数字",
    "4061": "请选择适用施肥阶段",
    "4062": "0<肥料元素占比总和≤100%，请重新输入",
    "4063": "肥料中文名称",
    "4064": "肥料日文名称",
    "5001": "用户管理",
    "5002": "用户手机号",
    "5003": "请输入手机号",
    "5004": "操作日志",
    "5005": "调整时长",
    "5006": "所属角色",
    "5007": "土地数量",
    "5008": "调整使用时长",
    "5009": "当前用户时长剩余：",
    "5010": "使用到期日期",
    "5011": "用户手机号",
    "5014": "注册时间",
    "5015": "到期时间",
    "5016": "剩余",
    "5017": "天",
    "5018": "试用版",
    "5019": "该手机号未注册，请先注册",
    "5020": "该手机号已注册，请检查手机号是否正确",
    "5021": "请选择操作名称",
    "5022": "超级管理员",
    "5023": "系统管理员",
    "5024": "管理员",
    "5025": "种植户",
    "5026": "试用用户",
    "5027": "知道了",
    "5028": "登录信息失效，系统将返回至登录页",
    "5029": "您当前为试用版,如需购买请联系:XX-XXXXXXXX",
    "5030": "试用时长不足,如需再次体验请联系管理员",
    "5031": "账号已到期,如需继续使用请联系管理员",
    "5032": "土地数量已达上限，如需新增更多，请联系管理员",
    "5033": "用户编辑",
    "5034": "请选择使用到期日期",
    "5035": "请选择所属角色",
    "5036": "请输入土地数量",
    "5037": "最多只能输入1000",
    "5038": "调整成功",
    "5039": "操作时间",
    "5040": "操作名称",
    "5041": "操作对象",
    "5042": "内容",
    "5043": "请输入内容",
    "5044": "操作人",
    "5045": "新建客户",
    "5046": "解散群组",
    "5047": "添加成员",
    "5048": "移出群组",
    "5049": "请填写正确的手机号",
    "5050": "请输入客户名称",
    "5051": "客户名称已存在",
    "5052": "只能添加技术员",
    "5053": "请选择操作对象",
    "5054": "客户名称",
    "5055": "解散群组后，成员间将无法查看彼此的土地信息。确定解散群组？",
    "5056": "确定将该成员移出客户群组",
    "5057": "客户分组",
    "5058": "解散",
    "5059": "请先勾选成员",
    "5060": "用户名",
    "6001": "基础数据",
    "6002": "新增基础数据",
    "6003": "请输入名称",
    "6004": "请选择基础数据类型",
    "6006": "属性",
    "6008": "名称",
    "6009": "状态",
    "6010": "备注",
    "6011": "基础数据类型",
    "6012": "有效",
    "6013": "无效",
    "6014": "请输入基础数据名称",
    "6015": "请输入1-15个字符的基础数据名称",
    "6016": "请输入200字以内的备注",
    "6017": "编辑基础数据",
    "6018": "请选择基础数据",
    "2144": "实际氮量"
  },
};
export default zh;