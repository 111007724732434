import Vue from 'vue';

const store = new Vue({
  data: {
    userInfo: null,
    langValue: localStorage.getItem('locale')
  },
  methods: {
    setUserInfo(userInfo) {
      store.userInfo = userInfo;
    },
    setLangValue(type){
      store.langValue = type
    }
  },
});

export default store;
