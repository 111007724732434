import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
import ja from './ja'
import zh from './zh'
import jaLocale from 'element-ui/lib/locale/lang/ja'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import locale from 'element-ui/lib/locale'

const i18n = new VueI18n({  
    locale: localStorage.getItem('locale') || 'zh', // 语言标识  
// 添加多语言（每一个语言标示对应一个语言文件）  
    messages: {    
        'zh':{...zh,...zhLocale},
        'ja':{...ja,...jaLocale}
    }})
    locale.i18n((key,value)=>i18n.t(key,value))
export default i18n