import dayjs from 'dayjs';
import {
  getAttrByKey,
  isAttrTrue,
  getPropertyValueFromRow,
} from '@/utils/util';

// 日期时间列 主要用来统一设置宽度，如果需要格式化，则开启shouldFormatter属性即可
export default {
  minWidth: 150,
  renderColumn(h, scope, ctx) {
    const {
      data: { attrs = {} },
    } = ctx;
    const value = getPropertyValueFromRow(scope.row, scope.column.property);
    const shouldFormatter = getAttrByKey(attrs, 'shouldFormatter');

    return isAttrTrue(shouldFormatter)
      ? dayjs(value).format('YYYY-MM-dd HH:mm:ss')
      : value;
  },
};
