export default [
  {
    path: '/layout/crop-management',
    redirect: '/layout/crop-management/list',
  },
  {
    path: 'crop-management',
    name: 'crop-management',
    component: () => import('@/views/layouts/crop-management/index'),
    children: [
      // 作物列表
      {
        path: 'list',
        name: 'crop-management-list',
        meta:{title:'作物列表页'},
        component: () => import('@/views/layouts/crop-management/list/index'),
      },
      // 作物类型
      {
        path: 'type',
        name: 'crop-management-type-list',
        meta:{title:'作物类型'},
        component: () => import('@/views/layouts/crop-management/type/index'),
      },
    ],
  },
];
