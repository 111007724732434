import { isEmpty } from '@/utils/util';

export default {
  name: 'FormEditabled',
  inject: ['elFormItem'],
  props: {
    value: null,
    readonly: Boolean,
  },
  render(h) {
    const value = isEmpty(this.value) ? this.elFormItem.fieldValue : this.value;
    const readonlyShow = isEmpty(value)
      ? h('span', '-')
      : this.$slots.readonly || h('span', value);
    const editabledShow = this.$slots.default;
    return this.readonly ? readonlyShow : editabledShow;
  },
};
