export default {
  // 土地列表
  getLandList: {
    url: '/field/list',
    method: 'get',
  },
  // 土地详情
  getLandDetails: {
    url: '/field/details',
    method: 'get',
  },
  // 新增土地
  createLand: {
    url: '/field/add',
    method: 'post',
  },
  // 更新土地
  updateLand: {
    url: '/field/update',
    method: 'post',
    config: {
      notFilterEmptyValue: true,
    },
  },
  // 删除土地
  delLand: {
    url: '/field/delete',
    method: 'post',
  },
  // 施肥设计记录
  getDesignRecordList: {
    url: '/field/designRecordHistory',
    method: 'get',
  },
  // 删除施肥设计记录
  delDesignRecord: {
    url: '/designRecord/delete',
    method: 'post',
  },
  // 种植记录
  getCropRecord: {
    url: '/field/cropHistory',
    method: 'get',
  },
};
