import Vue from 'vue';
import App from './App.vue';
import router from './router';
// 引入element-ui 作为依赖
import ElementUi from 'element-ui';
import PagodaUi from 'pagoda-ui';
import 'pagoda-ui/src/theme/theme1.scss';
import 'pagoda-ui/src/theme/ui-reset.scss';

// 按需加载 echarts 图表资源
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/bar';

// 按需加载 echarts 工具组件
import 'echarts/lib/component/markLine';

import PagodaCharts from 'pagoda-charts';

// 公共样式
import '@/styles/';

// 全局组件
import globalComponents from '@/components/global-components';

// utils
import api from '@/api';
import store from '@/utils/store';


// mock
import './mock/';

// sensors
import './utils/sensors'

// filters

import filters from '@/filters/index'
Object.keys(filters).forEach(key => {  
  Vue.filter(key, filters[key])  
}) 

Vue.use(globalComponents);

Vue.use(PagodaCharts, {
  echarts,
});

import i18n from "./lang/";


// 用来统一设置表单默认值，因为使用el-form的resetFields方法的时候没有默认值会有bug
ElementUi.FormItem.mixins.push({
  created() {
    if (this.prop && this.form.model) {
      const paths = this.prop.split('.');

      let model = this.form.model;
      paths.forEach(key => {
        if (!(key in model)) {
          this.$set(model, key, '');
        }

        model = model[key];
      });
      this.$changeBySetDefaultValue = true;

      this.$nextTick(() => (this.$changeBySetDefaultValue = false));
    }
  },
});
const validate = ElementUi.FormItem.methods.validate;
ElementUi.FormItem.methods.validate = function(...arg) {
  if (this.$changeBySetDefaultValue) {
    this.$changeBySetDefaultValue = false;
    return;
  }

  validate.call(this, ...arg);
};

ElementUi.Button.setDefaults({
  查询: {
    type: 'primary',
  },
  詮索する: {
    type: 'primary',
  },
  保存: {
    type: 'primary',
  },
  保存する: {
    type: 'primary',
  },
  提交: {
    type: 'primary',
  },
  新增: {
    type: 'primary',
  },
});

// 注意：需要在use element-ui 之前 use pagoda-ui


Vue.prototype.$store = store;
Vue.prototype.$API = api;

Vue.prototype.$filterNumber = function(setValue, val) {
  // 限制用户只能输入数字
  setValue(val.replace(/[^\d]/g, ''));
};
Vue.config.productionTip = false;
Vue.use(PagodaUi).use(ElementUi, { size: 'mini'});
Vue.prototype.$ENV = process.env.VUE_APP_ENV
Vue.use(ElementUi, {
  i18n: (key, value) => i18n.t(key, value)
});

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app');
