export function exportFile(blobPart, name) {
  const blob = new Blob([blobPart]);
  const url = window.URL.createObjectURL(blob);

  downloadLink(url, name);
}

export function downloadLink(linkUrl, name) {
  const link = document.createElement('a');
  link.download = name;
  link.href = linkUrl;

  link.click();
}

export function getJsonFromBlob(blobPart) {
  return new Promise(resolve => {
    const fileReader = new FileReader();
    fileReader.readAsText(blobPart);

    fileReader.onload = function() {
      let json = null;

      try {
        json = JSON.parse(this.result);
      } catch (err) {}

      resolve(json);
    };
  });
}
