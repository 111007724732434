import { ROLE_LABEL, ROLE } from '@/data-map/role';

export default {
  label:  localStorage.getItem('locale') === 'ja' ? 'ニックネーム' : '角色名称',
  prop: 'roleId',
  formatter(row, column, cellValue) {
    const roleName = ROLE_LABEL[cellValue];

    return cellValue === ROLE.TRIAL_USER && row.expire === 0
      ? `${roleName}(已失效)`
      : roleName;
  },
};
