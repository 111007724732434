export default [
  {
    path: '/layout/user-management',
    redirect: '/layout/user-management/list',
  },
  {
    path: 'user-management',
    name: 'user-management',
    component: () => import('@/views/layouts/user-management/index'),
    children: [
      // 用户管理列表页
      {
        path: 'list',
        name: 'user-management-list',
        meta:{title:'用户管理列表页'},
        component: () => import('@/views/layouts/user-management/list/index'),
      },
      // 操作记录列表页
      {
        path: 'operate-log',
        name: 'user-management-operate-log',
        meta:{title:'操作记录'},
        component: () =>
          import('@/views/layouts/user-management/operate-log/index'),
      },
      // 客户分组列表页
      {
        path: 'group',
        name: 'user-management-group',
        meta:{title:'客户分组列表页'},
        component: () =>
          import('@/views/layouts/user-management/group/index'),
      },
      {
        path: 'group/detail',
        name: 'user-management-group-detail',
        meta:{title:'客户分组详情'},
        component: () => import('@/views/layouts/user-management/group/detail'),
      },
    ],
  },
];
