import { getAttrByKey, getPropertyValueFromRow } from '@/utils/util';

export default {
  renderColumn(h, scope, ctx) {
    const {
      data: { attrs = {} },
    } = ctx;
    const value = getPropertyValueFromRow(scope.row, scope.column.property);
    const onClick = getAttrByKey(attrs, 'onClick');

    return (
      <a class="pagoda-link" onClick={() => onClick(scope)}>
        {value}
      </a>
    );
  },
};
