export default [
  {
    path: '/layout/base-data',
    redirect: '/layout/base-data/list',
  },
  {
    path: 'base-data',
    name: 'base-data',
    component: () => import('@/views/layouts/base-data/index'),
    children: [
      // 基础数据列表页
      {
        path: 'list',
        name: 'base-data-list',
        meta:{title:'基础数据列表页'},
        component: () => import('@/views/layouts/base-data/list/index'),
      },
    ],
  },
];
