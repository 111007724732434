export default [
  {
    name: 'home',
    path: 'home',
    meta:{
      title:'首页'
    },
    component: () => import('@/views/layouts/home'),
  },
];
