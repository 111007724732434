export default {
  // 基础数据列表
  getBaseDataList: {
    url: '/basicData/list',
    method: 'get',
  },
  // 新增基础数据
  createBaseData: {
    url: '/basicData/add',
    method: 'post',
  },
  // 更新基础数据
  editBaseData: {
    url: '/basicData/update',
    method: 'post',
    config: {
      notFilterEmptyValue: true,
    },
  },
  // 基础数据排序
  sortBaseData: {
    url: '/basicData/swap',
    method: 'post',
  },
  // 删除基础数据
  delBaseData: {
    url: '/basicData/delete',
    method: 'post',
    config: {
      errorMessageType: 'alert',
    },
  },
};
