import Vue from 'vue';
import VueRouter from 'vue-router';
import { Message } from 'element-ui';
// api
import API from '@/api/';

// utils
import {
  hasPermission,
  getOprateCodeList,
  isTrialUserAndInvalid,
  isGeneralUserAndInvalid,
} from '@/utils/permission';
import { extraComponentOptions } from '@/utils/router';
import { importAll, deepLoop } from '@/utils/util';
import alert from '@/utils/alert';
import { logingSensors } from '@/utils/sensors'
Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
const layouts = [];
const routes = [
  {
    path: '/',
    redirect: '/layout/home',
  },
  {
    name: 'login',
    path: '/login',
    meta:{title:'登录页'},
    component: () => import('@/views/login'),
  },
  {
    path: '/layout',
    redirect: '/layout/home',
  },
  {
    name: 'layout',
    path: '/layout',
    component: () => import('@/views/layouts/index'),
    children: layouts,
  },
];

importAll(routeModule => {
  layouts.push(...routeModule);
}, require.context('./routes/', false, /.js/));

deepLoop(routes, route => {
  if (!route.meta) route.meta = {};

  extraComponentOptions(route);
});

const router = new VueRouter({
  routes,
});

router.beforeEach(async (to, from, next) => {
  // 路由白名单
  const whiteList = ['login'];
  if (whiteList.includes(to.name)) return next();
  
  const userInfo = await API.getUserInfo.request();
  // 权限白名单
  const permitWhiteList = ['home'];
  logingSensors(userInfo)
  // 是否是已失效的试用用户
  if (isTrialUserAndInvalid(userInfo.roleId, userInfo.expire)) {
    await alert.trialUserInvalid();

    next({
      name: 'login',
    });
    // 是否是已失效的种植户
  } else if (isGeneralUserAndInvalid(userInfo.roleId, userInfo.expire)) {
    await alert.generalUserInvalid();

    next({
      name: 'login',
    });
    // 检测该用户是否有该页面访问权限
  } else if (
    permitWhiteList.includes(to.name) ||
    hasPermission(to.name, userInfo.roleId)
  ) {
    const operateCodeList = getOprateCodeList(to.name, userInfo.roleId);

    to.meta.operateCodeList = operateCodeList;
    to.meta.userInfo = userInfo;
    const pageTitle = (to.meta && to.meta.title) || ''
    document.title = '优农道-' + pageTitle
    next();
  } else {
    if (from.path === '/') {
      next({
        name: 'home',
      });
    }
    Message.info('无访问权限');
  }
});

router.afterEach((to, from) => {
  // 记住来源路由
  if (to.name !== from.name) {
    to.meta.$routeFrom = from;
  }
});

export default router;
