<script>
import Router from '@/router/index';
import { isDev } from '@/utils/util';

export default {
  name: 'operate-permission',
  functional: true,
  render(h, ctx) {
    const { children } = ctx;
    const currentRoute = Router.history.current;
    // 获取当前路由所有操作权限
    const operatePermissionCodes =
      currentRoute.meta.operatePermissionCodes || [];

    return isDev()
      ? children
      : children.filter(item => {
          const attrs = item.data?.attrs || {};
          const operateCode = attrs['operate-code'];

          return operateCode
            ? operatePermissionCodes.includes(operateCode)
            : true;
        });
  },
};
</script>