export default [
  {
    path: '/layout/fertilizer-management',
    redirect: '/layout/fertilizer-management/list',
  },
  {
    path: 'fertilizer-management',
    name: 'fertilizer-management',
    component: () => import('@/views/layouts/fertilizer-management/index'),
    children: [
      // 肥料列表
      {
        path: 'list',
        name: 'fertilizer-management-list',
        meta:{title:'肥料列表页'},
        component: () =>
          import('@/views/layouts/fertilizer-management/list/index'),
      },
      // 新增肥料
      {
        path: 'list/create',
        name: 'fertilizer-management-list-create',
        meta:{title:'新增肥料'},
        component: () =>
          import('@/views/layouts/fertilizer-management/list/create'),
      },
      // 编辑肥料
      {
        path: 'list/edit/:id',
        name: 'fertilizer-management-list-edit',
        meta:{title:'编辑肥料'},
        component: () =>
          import('@/views/layouts/fertilizer-management/list/edit'),
      },
      // 肥料详情
      {
        path: 'list/detail/:id',
        name: 'fertilizer-management-list-detail',
        meta:{title:'肥料详情'},
        component: () =>
          import('@/views/layouts/fertilizer-management/list/detail'),
      },
    ],
  },
];
