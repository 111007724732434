import PagodaTableColumn from 'pagoda-ui/src/components/data/table-v2/table-column';
import { importAll, isEmpty } from '@/utils/util';
import {
  getAttrByKey,
  getPropertyValueFromRow,
  isAttrTrue,
} from '@/utils/util';

const columnConfig = {};

importAll(columnConfig, require.context('./column-type/', false, /.js/));

export function generatorColumns(columns) {
  return columns.map(config => {
    let columnType = config.column;
    let columnOptions = config;
    if (columnType) {
      const defaultConfig = columnConfig[columnType];

      if (defaultConfig) {
        columnOptions = Object.assign({}, defaultConfig, config);
      } else {
        console.error(`${columnType}类型没有配置`);
      }
    }

    generatorColumnWidth(columnOptions);

    return columnOptions;
  });
}

function generatorColumnWidth(opt) {
  // 如果没有设置minWidth 则根据表头label的字数来设置一个默认的minWidth
  const { width, label } = opt;
  const minWidth = getAttrByKey(opt, 'minWidth');

  if (label && !width) {
    // 20 是表头左右padding的值
    const labelMinWidth = opt.label.length * 14 + 20;

    if (!minWidth || minWidth < labelMinWidth) opt.minWidth = labelMinWidth;
  }
}

function generatorEmptyValueRender(props, scopedSlots) {
  if (!scopedSlots.default) {
    const formatter = props.formatter;

    props.formatter = function(row, column, cellValue) {
      return isEmpty(cellValue)
        ? '-'
        : formatter
        ? formatter(row, column, cellValue)
        : cellValue;
    };
  } else {
    const defaultSlot = scopedSlots.default;

    scopedSlots.default = function(scope) {
      const cellValue = getPropertyValueFromRow(
        scope.row,
        scope.column.property
      );

      return isEmpty(cellValue) ? '-' : defaultSlot(scope);
    };
  }
}

export default {
  name: 'TableColumn',
  functional: true,
  render(h, ctx) {
    const { props, data } = ctx;
    const columnType = props.column;

    if (!data.scopedSlots) data.scopedSlots = {};
    if (!data.attrs) data.attrs = {};

    if (columnType) {
      const config = columnConfig[columnType];

      if (config) {
        const { renderColumn, renderHeader, ...defaultProps } = config;

        // 设置默认属性
        if (defaultProps) {
          Object.keys(defaultProps).forEach(prop => {
            if (!(prop in data.attrs)) {
              data.attrs[prop] = defaultProps[prop];
            }
          });
        }

        if (renderColumn) {
          // 如果没有传作用域插槽，则调用默认的作用域插槽
          if (!data.scopedSlots.default) {
            data.scopedSlots.default = function(scope) {
              return renderColumn(h, scope, ctx);
            };
          }
        }

        if (renderHeader) {
          // 如果没有传表头作用域插槽，则调用默认的表头作用域插槽
          if (!data.scopedSlots.header) {
            data.scopedSlots.header = function(scope) {
              return renderHeader(h, scope, ctx);
            };
          }
        }
      } else {
        console.error(`${columnType}类型没有配置`);
      }
    }

    const disableDisplayEmpty = getAttrByKey(data.attrs, 'disableDisplayEmpty');
    if (props.prop && !isAttrTrue(disableDisplayEmpty)) {
      generatorEmptyValueRender(data.attrs, data.scopedSlots);
    }

    generatorColumnWidth(data.attrs);

    return <PagodaTableColumn {...ctx.data}>{ctx.children}</PagodaTableColumn>;
  },
};
