export default {
  // 施肥分析记录
  getDesignRecord: {
    url: '/designRecord/details',
    method: 'get',
  },
  // 施肥分析
  getDesignPredict: {
    url: '/designRecord/predict',
    method: 'post',
  },
  // 获取施肥分析报告
  getDesignReport: {
    url: '/designReport/details',
    method: 'get',
  },
  // 保存施肥分析记录或生成施肥分析报告
  saveReport: {
    url: '/designRecordReport/save',
    method: 'post',
  },
  // 导出施肥分析报告
  exportDesignReport: {
    url: '/designReport/download',
    method: 'get',
  },
  // 保存年度总氮量设计
  saveNtotal: {
    url: '/designRecordReport/ntotal',
    method: 'post',
  },
  // 生成施肥分析报告
  createDesignReport: {
    url: '/designRecordReport/create',
    method: 'post',
  },
   // 保存实测值
  saveMeasured: {
    url: '/designRecordReport/measured',
    method: 'post',
  }, 
};
