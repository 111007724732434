export default [
  {
    path: '/layout/land-management',
    redirect: '/layout/land-management/list',
  },
  {
    path: 'land-management',
    name: 'land-management',
    component: () => import('@/views/layouts/land-management/index'),
    children: [
      // 土地列表
      {
        path: 'list',
        name: 'land-management-list',
        meta:{title:'土地列表页'},
        component: () => import('@/views/layouts/land-management/list/index'),
      },
      // 新增土地
      {
        path: 'list/create',
        name: 'land-management-list-create',
        meta:{title:'土地新增'},
        component: () => import('@/views/layouts/land-management/list/create'),
      },
      // 编辑土地
      {
        path: 'list/edit/:id',
        name: 'land-management-list-edit',
        meta:{title:'土地编辑'},
        component: () => import('@/views/layouts/land-management/list/edit'),
      },
      // 土地详情
      {
        path: 'list/detail/:id',
        name: 'land-management-list-detail',
        meta:{title:'土地详情'},
        component: () => import('@/views/layouts/land-management/list/detail'),
      },
      // 施肥设计
      {
        path: 'fertilize-design',
        name: 'land-management-fertilize-design',
        meta:{title:'施肥设计'},
        component: () =>
          import('@/views/layouts/land-management/fertilize-design/index'),
      },
      // 施肥设计编辑
      {
        path: 'fertilize-design/edit',
        name: 'land-management-fertilize-design-edit',
        meta:{title:'施肥设计编辑'},
        component: () =>
          import('@/views/layouts/land-management/fertilize-design/edit'),
      },
      // 施肥设计报告
      {
        path: 'fertilize-design/report/:id',
        name: 'land-management-fertilize-design-report',
        meta:{title:'施肥设计报告'},
        component: () =>
          import('@/views/layouts/land-management/fertilize-design/report'),
      },
      // 施肥设计详情
      {
        path: 'fertilize-design/detail',
        name: 'land-management-fertilize-design-detail',
        meta:{title:'施肥设计详情'},
        component: () =>
          import('@/views/layouts/land-management/fertilize-design/detail'),
      },
    ],
  },
];
