import { isFunction } from 'lodash-es';

export function extraComponentOptions(route) {
  const { component } = route;

  if (isFunction(component)) {
    route.component = async function() {
      const componentOptions = (await component()).default;

      componentOptions.name = route.name;

      return componentOptions;
    };
  } else if (component) {
    component.name = route.name;
  }
}
