import { isNumberOrNumberString } from '@/utils/util';
import { getAttrByKey, getPropertyValueFromRow } from '@/utils/util';

export default {
  renderColumn(h, scope, ctx) {
    const {
      data: { attrs = {} },
    } = ctx;
    const value = getPropertyValueFromRow(scope.row, scope.column.property);
    const toFixed = getAttrByKey(attrs, 'toFixed') || 1;

    return isNumberOrNumberString(value) ? value.toFixed(toFixed) : value;
  },
};
